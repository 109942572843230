<div class="sign_up_page">
  <!-- Sign up page image grid section start -->
  <div class="sign_up_page_single_image_grid">
    <div class="sign_in_page_single_image">
      <picture>
        <img
          src="assets/images/sign_in_img_9.webp"
          alt="sign-in-image"
          width="953"
          height="1005"
        />
      </picture>
    </div>
    <div class="sign_in_form_content">
      <div class="grid_footer_logo">
        <a routerLink="/"
          ><img
            src="assets/images/Logo.png"
            alt="logo-image"
            width="216"
            height="39"
        /></a>
      </div>
      <h2 class="h2">Download 10k+ Photos, Videos and editors choice</h2>
      <p>
        Access all the images, videos, music, and tools you need to turn ideas
        into achievements. All images
      </p>
    </div>
  </div>
  <!-- Sign up page image grid section end -->
  <!-- Sign up page form section start -->
  <div class="sign_up_page_image_form">
    <div class="sign_up_form">
      <div class="sign_up_form_heading">
        <h2 class="h2">Create an Account</h2>
        <p>
          Crate an account with us. Already have an account?
          <a routerLink="/signin">Sign In</a>
        </p>
      </div>
      <form [formGroup]="RegistrationForm" (ngSubmit)="onSubmit()">
        <div class="registration-form">
          <div
            class="form_group"
            [ngClass]="{ error_field: submitted && f.firstname.errors }"
          >
            <label for="name">Name</label>
            <input
              id="firstname"
              type="text"
              placeholder="Enter your name"
              formControlName="firstname"
            />
            <div
              *ngIf="submitted && f.firstname.errors"
              class="invalid-feedback"
            >
              <p *ngIf="f.firstname.errors.required" class="error">
                * Please enter valid name
              </p>
              <p *ngIf="f.firstname.errors.pattern" class="error">
                Please enter valid name
              </p>
            </div>
          </div>
          <div
            class="form_group"
            [ngClass]="{ error_field: submitted && f.email.errors }"
          >
            <label for="email">Email/Mobile no</label>
            <input
              type="text"
              placeholder="Enter your email or mobile no"
              formControlName="email"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <p *ngIf="f.email.errors.required" class="error">
                * Please enter valid email address or mobile number
              </p>
              <p *ngIf="f.email.errors.pattern" class="error">
                Please enter valid email address or mobile number
              </p>
            </div>
          </div>
          <div
            class="form_group"
            [ngClass]="{ error_field: submitted && f.password.errors }"
          >
            <label for="password">Password</label>
            <div class="eye_icon">
              <input
                [type]="passwordShow ? 'text' : 'password'"
                placeholder="Enter your password"
                formControlName="password"
              />
              <a href="javascript:void(0)" (click)="hideShowPassword()"
                ><img
                  [src]="
                    passwordShow
                      ? 'assets/images/eyeopen.svg'
                      : 'assets/images/eyeclose.svg'
                  "
                  class="{{ passwordShow ? 'eyeopen' : 'eyeclose' }}"
                  alt="{{ passwordShow ? 'eyeopen' : 'eyeclose' }}"
                  width="{{ passwordShow ? '21' : '21' }}"
                  height="{{ passwordShow ? '21' : '11' }}"
              /></a>
              <div *ngIf="!submitted" class="invalid-feedback">
                <p class="hint">
                  * Password contain at least one letter, one number, one
                  special character and 8 characters or longer.
                </p>
              </div>
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <p *ngIf="f.password.errors.required" class="error">
                  * Please enter valid password
                </p>
                <p *ngIf="f.password.errors.pattern" class="error">
                  * Please enter valid password
                </p>
              </div>
            </div>
          </div>
          <div
            class="form_group form_group_lastchild"
            [ngClass]="{ error_field: submitted && f.passwordConfirm.errors }"
          >
            <label for="password">Confirm Password</label>
            <div class="eye_icon">
              <input
                [type]="cpasswordShow ? 'text' : 'password'"
                placeholder="Enter your password"
                formControlName="passwordConfirm"
              />

              <a href="javascript:void(0)" (click)="hideShowConfirmPassword()"
                ><img
                  [src]="
                    cpasswordShow
                      ? 'assets/images/eyeopen.svg'
                      : 'assets/images/eyeclose.svg'
                  "
                  class="{{ cpasswordShow ? 'eyeopen' : 'eyeclose' }}"
                  alt="{{ cpasswordShow ? 'eyeopen' : 'eyeclose' }}"
                  width="{{ cpasswordShow ? '21' : '21' }}"
                  height="{{ cpasswordShow ? '21' : '11' }}"
              /></a>
              <div
                *ngIf="submitted && f.passwordConfirm.errors"
                class="invalid-feedback"
              >
                <p *ngIf="f.passwordConfirm.errors.required" class="error">
                  * Please enter valid confirm password
                </p>
                <p *ngIf="f.passwordConfirm.errors.mustMatch" class="error">
                  * Password and confirm password must match
                </p>
              </div>
            </div>
          </div>
          <div class="checkbox">
            <input
              type="checkbox"
              id="check"
              aria-label="checkbox"
              formControlName="iagree"
              [ngClass]="{
                'is-invalid': submitted && f.iagree.errors
              }"
            />
            <label for="text"
              >I agree to company
              <a routerLink="/terms-and-conditions"
                ><u>terms and conditions</u></a
              ></label
            ><br />
            <div
              *ngIf="submitted && f.iagree.errors"
              class="invalid-feedback terms-error"
            >
              <p *ngIf="f.iagree.errors.required" class="error-iagree">
                You Must Agree to the Terms and Conditions
              </p>
            </div>
          </div>
          <re-captcha
            (resolved)="resolved($event)"
            captchaSitekey="siteKey"
            formControlName="recaptcha"
            [ngClass]="{
              'is-invalid': submitted && f.recaptcha.errors
            }"
          ></re-captcha
          ><br />
          <div *ngIf="submitted && f.recaptcha.errors" class="invalid-feedback">
            <p *ngIf="f.recaptcha.errors.required" class="error-recaptcha">
              * Please Complete the CAPTCHA
            </p>
          </div>
        </div>

        <button class="sign_up_btn submit">Sign Up</button>
      </form>
      <p class="or"><span>OR</span></p>
      <div class="form_badges_btn">
        <div class="badges_btn">
          <a #loginRef>
            <img
              src="assets/images/google.svg"
              alt="google"
              width="24"
              height="24"
            />
            Continue With Google
          </a>
        </div>
        <div class="badges_btn">
          <a (click)="fbLogin()">
            <img
              src="assets/images/fb-sign-in.svg"
              alt="facebook"
              width="24"
              height="24"
            />
            Continue With Facebook
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Sign up page form section end -->
</div>
