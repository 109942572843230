// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: true,
  url: 'https://api.imagefootage.com/api/',
  front_url: 'https://imagefootage.com/',
  recaptcha: {
    siteKey: '6LeB8sIpAAAAAIr-N3Jo_vOOow7JFzBLeVAEmEvU',
  },
  googleClientId: '847224321582-0bqfq73tmamfr6pcr4u18o84jvhf36o7.apps.googleusercontent.com',
  fbAppId: '1203865754325627',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

