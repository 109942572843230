import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { messages } from 'src/appv2/appv2.constants';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { ToastService } from 'src/appv2/services/toast.service';

const phoneEmailRegex = /^(\d{10}|[a-zA-Z0-9_+]+([\.-]?[a-zA-Z0-9_+]+)*@[a-zA-Z0-9_+]+([\.-]?[a-zA-Z0-9_+]+)*(\.[a-zA-Z0-9_+]\w{2,3}))$/;

@Component({
  selector: 'appv2-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup;
  submitted = false;

  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private toastService: ToastService) { }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(phoneEmailRegex),
      ])),
    });
  }

  public onSubmit() {
    this.submitted = true;
    this.checkEmailMobile(this.forgotPasswordForm.value);
  }

  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.forgotPasswordLoading }))
    return
  }

  get f() { return this.forgotPasswordForm.controls; }

  public checkEmailMobile(formdata) {
    if (this.forgotPasswordForm.invalid) {
      for (const control of Object.keys(this.forgotPasswordForm.controls)) {
        this.forgotPasswordForm.controls[control].markAsTouched();
      }
      return;
    }

    if (formdata.email != '') {
      if (formdata.email.includes('@')) {
        this.loading(true);
        this.accountActions.passwordRecoveryEmail(formdata.email).subscribe((data) => {
          this.loading(false);
          if (data.status) {
            this.forgotPasswordForm.reset();
            this.submitted = false;
            this.toastService.success(data['message'])
          } else {
            this.toastService.warning(data['message'])
          }
        });

      } else {
        this.loading(true);
        this.accountActions.passwordRecoveryMobile(formdata.email).subscribe((data) => {
          this.loading(false);
          if (data.status) {
            data.is_email = false;
            data.verify_screen_used_for = 'forgot';
            this.accountActions.setRegisteredUserData(data);
            this.router.navigate(['/verify-email'])
            this.toastService.success(data['message'])
          } else {
            this.toastService.warning(data['message'])
          }
        });
      }


    }
  }

}
