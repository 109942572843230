import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { BehaviorSubject, EMPTY, from, Observable, of, throwError, } from 'rxjs';
import { SafeRequestService } from './safe-request.service';
import { environment } from 'src/environments/environment';
import { concatMap, delay, dematerialize, map, materialize } from 'rxjs/operators';
import { User } from '../models/user';
import { ActivatedRoute, Router } from '@angular/router';

declare const FB: any;
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private data: any = [];
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private baseUrl = environment.url;
  public accessToken:string
  public refreshToken:string

  // Define the userwishlist variable
  private userWishListSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public userWishLists: Observable<any> = this.userWishListSubject.asObservable();

  constructor(private safeRequest: SafeRequestService, private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  getLoginToken(email, password): Observable<any> {
    return this.http.post<User>(`${this.baseUrl}v2/login-v2`, { email, password })
      .pipe(map(user => {
        if (user.status) {
          localStorage.setItem('user_data', JSON.stringify(user.userdata));
          localStorage.setItem('user', JSON.stringify(user));
        }
        if (user.userdata) {
          this.setTokens(user.userdata.access_token, user.userdata.refresh_token)
          this.userSubject.next(user);
          this.startRefreshTokenTimer();
        }

        // store user details and jwt token in local storage to keep user logged in between page refreshes
        return user;
      }));
  }
  registerNewAccount(userData): Observable<any> {
    const url = 'signup'
    const body = userData
    return this.safeRequest.sendRequest('post', url, body)
  }
  passwordRecoveryEmail(email) {
    const url = 'validUser'
    const body = {
      "email": {
        "user_email": email
      }
    };

    return this.safeRequest.sendRequest('post', url, body)
  }
  passwordRecoveryMobile(mobile) {
    const url = 'validMobileUser'
    const body = {
      "mobile": {
        "user_mobile": mobile
      }
    };

    return this.safeRequest.sendRequest('post', url, body)
  }
  passwordChange(passwords, user) {
    const url = 'userchangepassword'
    const body = {
      "cpassword": passwords.cpassword,
      "old_pass": passwords.old_pass,
      "password": passwords.password,
      "userid": user
    };

    return this.safeRequest.sendRequest('post', url, body)
  }

  /**
   * reset the password
   * @param props (email, otp, password, cpassword)
   */
  resetPassword(props: any = {}) {
    const url = 'forgotResetPassword'
    const body = {
      ...props
    };
    return this.safeRequest.sendRequest('post', url, body)
  }

  sendContactUsMessage(name, email, subject, mobile, message) {
    const url = 'user_contactus'
    const body = {
      "mobile_number": mobile,
      "user_email": email,
      "user_message": message,
      "user_name": name,
      "user_subject": subject
    };
    return this.safeRequest.sendRequest('post', url, body)
  }
  getUserInformation(userId) {
    const url = 'userprofile/' + userId
    return this.safeRequest.sendRequest('get', url)
  }

  setUserInformation(profileData, tokenData) {
    const url = 'update_profile';
    const body = {
      profileData: profileData,
      tokenData: tokenData
    }
    return this.safeRequest.sendRequest('post', url, body)
  }


  /**For new version designs */
  signupV2Account(userData): Observable<any> {
    const url = 'v2/signup-v2'
    const body = userData
    return this.safeRequest.sendRequest('post', url, body)
  }

  /**For new version designs */
  setRegisteredUserData(data: any) {
    this.data = data;
  }

  getRegisteredUserData(): any {
    return this.data;
  }

  resendOtp(payload): Observable<any> {
    const url = 'resend_otp'
    const body = payload
    return this.safeRequest.sendRequest('post', url, body)
  }

  verifyOtp(payload): Observable<any> {
    const url = 'verify_mobile'
    const body = payload
    return this.safeRequest.sendRequest('post', url, body)
  }

  verifyEmailLink(token): Observable<any> {
    const url = 'active_user_account/' + token
    const body = []
    return this.safeRequest.sendRequest('get', url, body)
  }

  resendEmailLink(payload): Observable<any> {
    const url = 'resend_verification_link'
    const body = payload
    return this.safeRequest.sendRequest('post', url, body)
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user_data');
    localStorage.removeItem('formData');
    localStorage.removeItem('user');
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/signin']);
  }

  getCountries() {
    const url = 'get_countries_list'
    return this.safeRequest.sendRequest('get', url)
  }

  getStates(country_id) {
    const url = 'get_states_list/' + country_id
    return this.safeRequest.sendRequest('get', url)
  }

  getCities(state_id) {
    const url = 'get_cities_list/' + state_id
    return this.safeRequest.sendRequest('get', url)
  }

  getPurchaseHistory(payload, currentPage): Observable<any> {
    const url = 'purchase-history?page=' + currentPage
    const body = payload
    return this.safeRequest.sendRequest('post', url, body)
  }

  getDownloadHistory(payload, currentPage): Observable<any> {
    const url = 'download-history?page=' + currentPage
    const body = payload
    return this.safeRequest.sendRequest('post', url, body)
  }


  getMyPlan(payload,currentPage): Observable<any> {
    const url = 'myplan?page='+currentPage
    const body = payload
    return this.safeRequest.sendRequest('post', url, body)
  }

  getPackages(): Observable<any> {
    const url = 'v2/packages-v2'
    return this.safeRequest.sendRequest('get', url)
  }

  deleteAccount(payload): Observable<any> {
    const url = 'user/delete-account/' + payload
    // const body = payload
    return this.safeRequest.sendRequest('post', url)
  }

  // Method to update userwishlist
  updateUserWishlist(wishlistData: any) {
    this.userWishListSubject.next(wishlistData);
  }

  getShareLink(payload): Observable<any> {
    const url = 'share-wishlist'
    const body = payload
    return this.safeRequest.sendRequest('post', url, body)
  }

  // GoogleSocialLogin
  googleSocialLogin(payload): Observable<any> {
    return this.http.post<User>(`${this.baseUrl}v2/social-login`, payload)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user_data', JSON.stringify(user.userdata));
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      }));
  }

  fbInItlogin() {
    // login with facebook then authenticate with the API to get a JWT auth token
    this.facebookLogin()
      .pipe(concatMap(accessToken => this.apiAuthenticate(accessToken)))
      .subscribe(() => {
        // get return url from query parameters or default to home page
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
      });
  }

  facebookLogin() {
    // login with facebook and return observable with fb access token on success
    return from(new Promise<any>(resolve => FB.login(resolve)))
      .pipe(concatMap(({ authResponse }) => {
        if (!authResponse) return EMPTY;
        return of(authResponse.accessToken);
      }));
  }

  apiAuthenticate(accessToken: string) {
    // authenticate with the api using a facebook access token,

    return from(new Promise(resolve => {
      fetch(`https://graph.facebook.com/v8.0/me?fields=id,name,first_name,last_name,email&access_token=${accessToken}`)
        .then(response => resolve(response.json()));
    })).pipe(concatMap((data: any) => {
      if (data.error) return this.unauthorized(data.error.message);;
      let payload = {};
      payload['provider'] = 'facebook';
      payload['email'] = data.email;
      payload['first_name'] = data.first_name;
      payload['last_name'] = data.last_name;
      payload['user_name'] = data.name;
      payload['idToken'] = data.id;
      payload['token'] = accessToken

      return this.http.post<User>(`${this.baseUrl}v2/social-login`, payload)
        .pipe(map(user => {
          localStorage.setItem('user_data', JSON.stringify(user.userdata));
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        }));
    }));
  }

  unauthorized(message = 'Unauthorized') {
    return throwError({ status: 401, error: { message } })
      .pipe(materialize(), delay(500), dematerialize());
  }

  getFilterOptions(payload): Observable<any> {
    const url = 'get-side-filters-v2'
    const body = payload
    return this.safeRequest.sendRequest('post', url, body)
  }

  getAccessToken() {
    return this.accessToken;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  setTokens(accessToken: string, refreshToken: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;

  }

  clearTokens() {
    this.accessToken = null;
    this.refreshToken = null;
  }

  refreshAccessToken(){

    return this.http.post<any>(`${this.baseUrl}v2/refresh`, {})
            .pipe(map((user) => {
              if (user.status) {
                localStorage.setItem('user_data', JSON.stringify(user.userdata));
                localStorage.setItem('user', JSON.stringify(user));
              }
              this.setTokens(user.userdata.access_token,user.userdata.refresh_token)

              // store user details and jwt token in local storage to keep user logged in between page refreshes
              this.userSubject.next(user);
                this.startRefreshTokenTimer();
                return user;
            }));

  }
  private refreshTokenTimeout;


  private startRefreshTokenTimer() {
    const expiresInMinutes = this.userValue.userdata.expires_in;
    const timeout = (expiresInMinutes - 1) * 60 * 1000;

    this.refreshTokenTimeout = setTimeout(() => this.refreshAccessToken().subscribe(), timeout);
}
  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

}
