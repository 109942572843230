import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ToastService } from 'src/appv2/services/toast.service';
import { loadingChangeAction } from 'src/appv2/reducers/loading/loading.actions';
import { LoadingState } from 'src/appv2/reducers/loading/loading.models';
import { AccountService } from 'src/appv2/services/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConfirmPasswordValidator } from 'src/appv2/validators/confirm-password.validator';
import { messages } from 'src/appv2/appv2.constants';

const phoneEmailRegex = /^(\d{10}|[a-zA-Z0-9_+]+([\.-]?[a-zA-Z0-9_+]+)*@[a-zA-Z0-9_+]+([\.-]?[a-zA-Z0-9_+]+)*(\.[a-zA-Z0-9_+]\w{2,3}))$/;
const nameRegex = /^[A-Za-z\s]+$/;
const passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&+*-.=<>()|^]).{8,}$/;
@Component({
  selector: 'appv2-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  success: string = ''
  RegistrationForm: FormGroup;
  data: Array<any>;
  cpasswordShow: boolean;
  passwordShow: boolean;
  token: string | undefined;
  captchaSitekey: string;
  submitted = false;
  auth2: any;

  @ViewChild('loginRef', { static: true }) loginElement: ElementRef;

  constructor(private store$: Store<any>, private accountActions: AccountService, private router: Router, private route: ActivatedRoute, private toastService: ToastService) {
    this.data = new Array<any>()
    this.token = undefined;
    this.captchaSitekey = environment.recaptcha.siteKey
  }

  ngOnInit(): void {
    this.RegistrationForm = new FormGroup({
      'password': new FormControl('', [Validators.compose([
        Validators.required,
        Validators.pattern(passwordRegex)
      ])]),
      'passwordConfirm': new FormControl('', [Validators.required]),
      'email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(phoneEmailRegex),
      ])),
      'firstname': new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(nameRegex),
      ])),
      'iagree': new FormControl('', [Validators.required]),
      'recaptcha': new FormControl('', [Validators.required])
    }, {
      validators: ConfirmPasswordValidator.mustMatch("password", "passwordConfirm")
    })
    this.googleAuthSDK();
  }

  get f() { return this.RegistrationForm.controls; }

  public onSubmit() {
    this.submitted = true;
    this.getDataFromApi(this.RegistrationForm.value);
  }
  public loading(status: boolean) {
    this.store$.dispatch(new loadingChangeAction(<LoadingState>{ loadingStatus: status, loadingMessage: messages.signUpLoading }))
    return
  }

  getDataFromApi(userData) {

    if (this.RegistrationForm.invalid) {
      for (const control of Object.keys(this.RegistrationForm.controls)) {
        this.RegistrationForm.controls[control].markAsTouched();
      }
      return;
    }

    if (userData.email != '' && userData.password != '' && userData.name != '' && userData.passwordConfirm != '' && userData.iagree) {

      this.loading(true)
      userData.mobile = userData.phone
      userData.name = userData.firstname
      this.accountActions.signupV2Account(userData).subscribe((data) => {
        this.loading(false)
        if (data.status) {
          data.verify_screen_used_for = 'signup';
          this.accountActions.setRegisteredUserData(data);
          localStorage.setItem('registered_info', JSON.stringify(data));
          this.router.navigate(['/verify-email'])
        } else {
          this.toastService.warning(data['message'])
        }
      });

    }
  }

  public hideShowPassword() {
    this.passwordShow = !this.passwordShow;
  }

  public hideShowConfirmPassword() {
    this.cpasswordShow = !this.cpasswordShow;
  }

  public resolved(captchaResponse: string) {
    this.token = captchaResponse;
  }

  googleAuthSDK() {

    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: environment.googleClientId,
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
          plugin_name: 'PLUGIN'
        });
        this.callLoginButton();
      });
    }

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));

  }

  callLoginButton() {

    this.auth2.attachClickHandler(this.loginElement.nativeElement, {},
      (googleAuthUser) => {

        let profile = googleAuthUser.getBasicProfile();

        /* Write Your Code Here */
        let payload = {};
        payload['provider'] = 'google';
        payload['email'] = profile.getEmail();
        payload['name'] = profile.getName();
        payload['idToken'] = profile.getId();
        payload['image'] = profile.getImageUrl();

        this.accountActions.googleSocialLogin(payload).pipe()
          .subscribe({
            next: (data) => {
              this.loading(false);
              if (data.status) {
                // get return url from query parameters or default to home page
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                this.toastService.success(messages.loginSuccess)
                this.router.navigateByUrl(returnUrl);
              } else {
                this.toastService.warning(data.message)
              }
            },
            error: error => {
              this.loading(false);
              this.toastService.error(error)
            }
          });

      }, (error) => {
        // alert(JSON.stringify(error, undefined, 2));
      });

  }

  fbLogin() {
    this.accountActions.fbInItlogin();
  }

}
